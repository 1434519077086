.gap-10 {
  gap: 10px;
}
section.siup-nav a:hover {
  color: #ed6144 !important;
}

section.siup-nav a {
  font-size: 14px;
  font-weight: 500;
  transition: 0.4s;
}
.theme-clr {
  color: #ed6144 !important;
}
.fw-sbold {
  font-weight: 600;
}
.bannerContent h2 {
  font-size: 42px;
  line-height: 50px;
}
.common-btn {
  background: #ef6630;
  color: #fff;
  padding: 0 30px;
  height: 45px;
  font-size: 14px;
  text-transform: capitalize;
  white-space: nowrap;
}
.bannerContent .btn-wrp p {
  font-size: 14px;
  font-weight: 500;
}
section.iconSec .cardCstm h4 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
section.iconSec .cardCstm p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #606060;
}
.ProcessOfWork .inner p {
  font-size: 14px;
  line-height: 28px;
}
.ProcessOfWork .inner h4 {
  font-size: 18px;
  font-weight: 700;
}
.ProcessOfWork:after {
  position: absolute;
  content: "";
  left: 0;
  top: 5px;
  /* transform: translateY(-50%); */
  height: 70%;
  background: #000;
  width: 1px;
}
.ProcessOfWork li:after {
  position: absolute;
  content: "";
  left: -27px;
  top: 5px;
  /* transform: translateY(-50%); */
  height: 12px;
  width: 8px;
  background: #eb604a;
  z-index: 99;
  border-top: 2px solid #fffcfc;
  border-bottom: 2px solid #fffcfc;
}
.content-wrp p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.content-wrp h4 {
  font-size: 20px;
}
section.Applink .cardCstm .content {
  background: #000;
  border-radius: 4px;
  color: #fff;
  padding: 9px;
}

section.Applink .cardCstm .content h6 {
  font-size: 13px;
  text-align: center;
}

section.Applink .cardCstm .img-wrp {
  max-width: 120px;
}

section.Applink .cardCstm:hover .content {
  background: #eb604a;
  transition: 0.4s;
}
.account-form .modal-content {
  border: 0;
  border-radius: 15px;
}
.account-form .modal-dialog {
  max-width: 800px;
}
.account-form .modal-dialog .modal-title {
  font-size: 30px;
  font-weight: 700;
}
.uploadFile input.position-absolute {
  left: 0;
  top: 0;
  z-index: 99;
  cursor: pointer;
  opacity: 0;
}
.account-form form p {
  font-size: 14px;
}
.uploadFile .inner img {
  width: 100px;
  height: 100px;
}
.uploadFile input.position-absolute {
  left: 0;
  top: 0;
  z-index: 99;
  cursor: pointer;
  opacity: 0;
}

.uploadFile .inner img {
  width: 100px;
  height: 100px;
}

.account-form form p {
  font-size: 14px;
}

.account-form form label.form-label {
  font-size: 14px;
  font-weight: 600;
  color: #505050;
}
.icon-with-text span.icn {
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}
.account-form form .form-control {
  font-size: 14px;
  font-weight: 600;
  padding-left: 37px;
}
textarea {
  resize: none;
}
.account-form form input.form-control,
.account-form form select {
  height: 50px;
}
.upload-fileList input.position-absolute {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  cursor: pointer;
  opacity: 0;
}
.account-form form input.form-control:focus {
  border: 1px solid #ed6144;
}
/* .modal-dialog-scrollable .modal-body::-webkit-scrollbar {
  display: none;
} */
.cstmizeList li input.position-absolute {
  width: 100%;
  height: 100%;
  z-index: 9;
  cursor: pointer;
  opacity: 0;
}

.cstmizeList li .profileWrp .imgWrp {
  flex-shrink: 0;
}

.cstmizeList li .profileWrp .imgWrp img {
  height: 70px;
  border-radius: 10px;
  width: 70px;
  object-fit: cover;
}

.cstmizeList li .profileWrp .content h6 {
  font-size: 18px;
  font-weight: 700;
}
.cstmizeList li .cstmCard span.check {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 18px;
  width: 18px;
  border: 1px solid #000;
  border-radius: 3px;
}
.cstmizeList li .cstmCard span.check svg {
  height: 100%;
  width: 100%;
}
.cstmizeList li input.position-absolute:checked + div span.check {
  background: #000;
}
.cstmizeList > li:not(:last-child) {
  border-bottom: 1px dashed #000 !important;
}
.CustomizeAddOn .modal-dialog {
  max-width: 700px;
}
.CustomizeAddOn button.checkout-btn {
  max-width: 240px;
}
.orderDetailwrp.table .orderDetail .imgWrp {
  flex-shrink: 0;
}
/* sweet-alert.css */

/* Center the alert */
.swal2-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Custom styling for the alert */
.swal2-popup {
  width: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(172, 74, 74, 0.1);
  text-align: center;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: #f16114 !important;
  border-radius: 0.25em;
  background: initial;
  background-color: #f16114 !important;
  color: #fff;
  font-size: 1em;
}
.swal2-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.swal2-content {
  font-size: 18px;
  margin-bottom: 20px;
}

.swal2-buttons-container {
  display: flex;
  justify-content: center;
}

.orderDetailwrp.table .orderDetail .imgWrp img {
  height: 60px;
  width: 80px;
  object-fit: cover;
  border-radius: 8px;
}
body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

/* .CustomizeAddOn .modal-body::-webkit-scrollbar {
  display: block !important;
} */
.orderDetailwrp.table td,
.orderDetailwrp.table th {
  vertical-align: middle;
  background-color: transparent;
}
.listCustomize {
  row-gap: 15px;
}
section.cart-main .form-check-label {
  font-size: 14px;
}
.tacos-card .price-part .btn-add {
  font-size: 12px;
  border-radius: 4px;
}
.restaurantView.modal .restList > li {
  width: calc(20% - 8px);
  padding: 5px;
}

.restaurantView.modal .restList > li img.img-fluid {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1;
  height: auto;
}

.restaurantView.modal .restList > li p {
  margin: 0;
  font-size: 12px;
}
img.cartImage {
  width: 100px;
  border-radius: 20px;
}
@media (max-width: 992px) {
  section.signup-sec .img-wrpper {
    order: -1;
  }
}
