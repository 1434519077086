.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.common-backbtn {
  background: #adb5bd !important;
  color: #fff;
  padding: 0 30px;
  height: 45px;
  font-size: 14px;
  text-transform: capitalize;
}
.custom-tooltip .tooltip-inner {
  background: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: 1px solid #ddd;
}

.custom-tooltip .tooltip-arrow::before {
  filter: invert(1);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.custom-vk .btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: gray;
}

.CartSidebar .btn-wrp .common-btn {
  color: #fff;
  background: #ef6630;
}
html {
  scroll-behavior: smooth;
}



.closeres {
  filter: grayscale(1);
  cursor: not-allowed;
  pointer-events: none;
}